.block-digital-promotion {
    margin-left: auto;
    margin-left: auto;
    margin-bottom: 3.5em;

    > .pad {
        margin: 0 auto;
        padding-left: $mobile-padding;
        padding-right: $mobile-padding;
        min-height: 400px;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: cover;
        position: relative;
        @include tablet {
            min-height: 46vw;
        }

        &:before {
            content: " ";
            position: absolute;
            top: 175px;
            display: inline-block;
            background: url(/FNBHutchinson/media/Graphics/Homepage%20Images/home_digital-promo.jpg);
            width: calc(100% - 45px);
            height: 100%;
            background-size:contain;
            background-position:center;
            background-repeat: no-repeat;
            z-index: -1;
            @media screen and (min-width: 400px) {
                top: 160px;
            }
            @media screen and (min-width: 800px) {
                top: 155px;
                width: calc(100% - 30px);
            }
        }

        > .content {
            padding-top: 1.5em;
            margin: 0 auto;
            @include max-wrap;
            text-align: center;
            display: grid;
            gap: 2rem 0;
            @include tablet {
                padding-top: 2em;
            }
        }
    }

    .title {
        margin: 0 auto;
        font-family: $heading-font;
        color: $body-link-color;
        font-weight: bold;
        font-size: 2.2rem;
        line-height: 1;

        @include tablet {
            @include fontSize(4);
        }
    }

    .body {
        font-size: 1.6rem;

        @include tablet {
            @include fontSize(1.8);
        }

        > *:last-child {
            margin: 0;
        }
    }

    .actions-btn {
        margin: 0;
        padding: 0;
        list-style: none;
        display: grid;
        gap: 1.5em 0;
    }

    .actions-icons {
        margin: 0 auto;

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0 1em;
        }

        li {
            font-size: 1.8rem;

            @include tablet {
                font-size: 1.6rem;
            }
        }

        a {
            display: inline-block;

            &:focus, &:hover {
                img {
                    transform: scale(1.15);
                    transition: transform 0.5s ease;
                }
            }

            img {
                transform: scale(1);
                transition: transform 0.5s ease;
            }
        }
    }
}
