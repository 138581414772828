//**
// Project Settings
//**

// Consistent settings
$system-font-stack: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif; // System fonts


//Fonts
$body-font: "lato", sans-serif;
$heading-font: "rooney-web", serif;


// Content Sizes
$mobile-padding: 30px;
$max-wrap-xsmall: 800px; // Secondary max width for inner content areas
$max-wrap-small: 900px; // Secondary max width for inner content areas
$max-wrap: 1400px; // Max width for content areas
$max-wrap-large: 1440px; // Max width for content areas


// Main Responsive breakpoints
$tablet-small-break: 600px;
$tablet-break: 900px;
$desktop-break: 1250px;


// Additional Optional Breakpoints
$large-tablet-break: 1050px;


// Project color variables
$primary-color: #005499; //Blue
$secondary-color: #FFC846; //Gold
$tertiary-color: #F18701; //Orange
$green-background-color: #009CA2;
$yellow-background-color: #FFE6B3;
$blue-background-color: #F2F6FF;
$body-font-color: #292929;
$body-link-color: #092A6D;
$white: #ffffff;
$black: #000000;
$alert-color: #8D2D2D;
$notice-color: #C0C9E2;
$tab-background-color: #F9F9F9;
$form-input-border: #BFCEEA;
