.mod-feature {
    margin: 0 auto 5rem;
    padding: 0 $mobile-padding;
    overflow: hidden;

    @include tablet {
        margin: 0 auto -5rem;
        position: relative;
        z-index: 10;
    }

    @media screen and (min-width: 1050px) {
        margin-bottom: -10rem;
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: -15rem;
    }

    @media screen and (min-width: 1300px) {
        margin-bottom: -20rem;
    }



    &.image-left {
        .content .image {
            order: 1;
        }

        .content .text {
            order: 2;
        }
    }

    &.image-right {
        .content .image {
            order: 2;
        }

        .content .text {
            order: 1;
        }
    }

    > .content {
        @include max-wrap;
        display: grid;
        justify-content: center;
        gap: 3rem 0;

        @include tablet {
            grid-template-columns: 1fr 0.5fr;
            align-items: stretch;
            gap: 0 5rem;
        }
    }

    .image {
        margin: 0 auto;
        text-align: center;
        width: 100%;
        height: auto;
        svg.svg-image {
            width: 100%;
            max-width: 100%;
            height: auto;
        }
        .video-outline {
            margin: 0;
            padding: 0 4% 2%;
            width: 100%;
            height: 100%;
            aspect-ratio: 876 / 681;
            background: url('../Images/body_feature_video-outline.svg') transparent no-repeat center center / 100% 100%;
            .video-feature {
                position: relative;
                clip-path: url('#video-feature-clip');
                width: 100%;
                height: 100%;
                aspect-ratio: 876 / 681;
                video {
                    object-fit: cover;
                    object-position: center;
                    width: 100%;
                    height: 100%;
                    position: relative;
                    z-index: 9;
                }
            }
        }
    }

    .text {
        display: flex;
        align-items: center;
        text-align: center;

        @include tablet {
            text-align: left;
            padding-bottom: 10rem;
        }

        .wrapper {
            display: grid;
            gap: 1rem 0;
            width: 100%;
            @include tablet {
                gap: 2rem 0;
            }
        }

        .title {
            margin: 0;
            font-family: $heading-font;
            color: $body-link-color;
            font-weight: bold;
            font-size: 3rem;

            @include tablet {
                @include fontSize(5.5);
            }
        }

        .body {
            margin: 0;
            font-size: 1.6rem;

            @include tablet {
                @include fontSize(2.2);
            }

            > *:last-child {
                margin: 0;
            }
        }

        .actions {
            margin: 0;
            padding: 1rem 0 0 0;
            list-style: none;
            display: grid;
            gap: 2rem 0;
        }
    }
}
