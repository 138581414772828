/** DEFAULT SPEEDBUMP STYLES **/
.speedbump_back, .js-email_pop {
    background-color: rgba(0, 0, 0, 0.7) !important;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9100;
    text-align: center;
}
.speedbump_pop_inner, .email_pop_inner {
    display: block;
    max-width: 450px;
    position: fixed;
    width: 100%;
    height: 100%;
    top: -100%;
    left: 0;
    transform: translate(0, 0);
    transition: top .35s ease-in-out;
    .sb-active & {
        top: 0;
    }
    @include responsive(450px) {
        position: absolute;
        width: 90%;
        height: auto;
        top: -50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: top .35s ease-in-out;
        .sb-active & {
            top: 50%;
        }
    }
}
.speedbump_pop_content, .email_pop_content {
    background: $white;
    border-radius: 0;
    padding: 1.5em;
    text-align: center;
    overflow-y: auto;
    height: 100%;
    @include responsive(450px) {
        border-radius: 15px;
        height: auto;
    }
}
.speedbump_pop_header, .email_pop_header {
    margin: 0 auto 16px;
    color: $body-link-color;
    font-family: $heading-font;
    font-weight: bold;
    font-size: 1.8rem;
}
.speedbump_pop_message, .email_pop_message {
    margin: 0 auto 16px;
    padding: 0;
    color: $black;
    font-size: 1.6rem;
    font-family: $body-font;
    line-height: 1.5;
    > *:last-child {
        margin: 0 auto;
    }
}
.speedbump_pop_buttons, .email_pop_buttons {
    margin: 0 auto;
    display: grid;
    gap: 16px;
}
.speedbump_pop_btn_ok, .email_pop_btn_ok {
    @extend .cta-btn;
    font-size: 1.6rem;
}
.speedbump_pop_btn_cancel, .email_pop_btn_cancel {
    @extend .ghost-btn;
    font-size: 1.6rem;
}
