@font-face {
    font-family: 'jenna_sue_proregular';
    src: url('fonts/jenna_sue_pro-webfont.woff2') format('woff2'),
         url('fonts/jenna_sue_pro-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

// Typography
//
html {
  font-family: $system-font-stack;
  color: $black;
}
body {
  font-family: $body-font;
  font-style: normal;
  font-weight: 400;
  line-height: 1.7;
  font-size: 1.8rem;
}
h1,h2,h3,h4,h5,h6 {
    line-height: 1.3;
    p {
        margin: 0;
    }
}
[role=button] {
  cursor: pointer;
}
button {
    background: none;
    border: none;
}

.type-color-dark {
    color: $body-font-color;
    h1, .f-h1, h2, .f-h2, h3, .f-h3, h4, .f-h4, h5, .f-h5, h6, .f-h6 {
        color: $body-link-color !important;
    }
    a:not([class]) {
        color: $body-link-color !important;
    }
}
.type-color-light {
    color: $white;
    h1, .f-h1, h2, .f-h2, h3, .f-h3, h4, .f-h4, h5, .f-h5, h6, .f-h6 {
        color: $white !important;
    }
    a:not([class]) {
        color: $white !important;
    }
}

ul:not([class]) {
    margin: 0 auto 2.5em;
    padding: 0 0 0 1.5em;
    li {
        margin: 0 0 1em;
        text-align: left;
        break-inside: avoid;
    }
}

//** heading styles
.f-h1, h1:not([class]) {
    font-family: $heading-font;
    font-weight: bold;
    font-size: 3.2rem;
    line-height: 1.5;
    color: $body-link-color;
    @include tablet {
        @include fontSize(6);
        line-height: 1;
    }
}
.f-h2, h2:not([class]) {
    font-family: $heading-font;
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.5;
    color: $body-link-color;
    @include tablet {
        @include fontSize(3.8);
        line-height: 1.25;
    }
}
.f-h3, h3:not([class]) {
    font-family: $heading-font;
    font-weight: bold;
    font-size: 2.2rem;
    line-height: 1.5;
    color: $body-link-color;
    @include tablet {
        @include fontSize(3);
        line-height: 1;
    }
}
.f-h4, h4:not([class]) {
    font-family: $heading-font;
    font-weight: bold;
    font-size: 2.1rem;
    line-height: 1.5;
    color: $body-link-color;
    @include tablet {
        @include fontSize(2.4);
        line-height: 1;
    }
}
.f-h5, h5:not([class]) {
    font-family: $heading-font;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 1.5;
    color: $body-link-color;
    @include tablet {
        @include fontSize(2);
        line-height: 1.5;
    }
}
.f-h6, h6:not([class]) {
    font-family: $body-font;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 1.5;
    color: $body-link-color;
}
.f-disclosure {
    font-size: 1.8rem;
    line-height: 1.5;
    font-style: italic;
}

//** link styles
// a:not([class]) {
//   color: $body-link-color;
//   font-weight: bold;
//   text-decoration: underline;
// }

.content {
  font-family: $body-font;
}

main a:not([class]), main a[class='speedbump'] {
    color: $body-link-color;
    font-weight: bold;
    text-decoration: none;
    &:focus, &:hover {
        color: $body-link-color;
        text-decoration: underline;
        font-weight: bold;
    }
}


.margin-bottom-none, .no-margin-bottom {
  margin-bottom: 0 !important;
}
.margin-top-none {
  margin-top: 0 !important;
}

.margin-top-small {
    margin-top: .5em !important;
}
.margin-bottom-small {
    margin-bottom: .5em !important;
}
.margin-top-medium {
    margin-top: 1.5em !important;
}
.margin-bottom-medium {
    margin-bottom: 1.5em !important;
}
.margin-top-large {
    margin-top: 2.5em !important;
}
.margin-bottom-large {
    margin-bottom: 2.5em !important;
}
.margin-top-xlarge {
    margin-top: 3.5em !important;
}
.margin-bottom-xlarge {
    margin-bottom: 3.5em !important;
}
.margin-top-xxlarge {
    margin-top: 5em !important;
}
.margin-bottom-xxlarge {
    margin-bottom: 5em !important;
}

@include tablet {
    .margin-top-small {
        margin-top: 1em !important;
    }

    .margin-bottom-small {
        margin-bottom: 1em !important;
    }

    .margin-top-medium {
        margin-top: 3em !important;
    }

    .margin-bottom-medium {
        margin-bottom: 3em !important;
    }

    .margin-top-large {
        margin-top: 5em !important;
    }

    .margin-bottom-large {
        margin-bottom: 5em !important;
    }

    .margin-top-xlarge {
        margin-top: 7em !important;
    }

    .margin-bottom-xlarge {
        margin-bottom: 7em !important;
    }

    .margin-top-xxlarge {
        margin-top: 10em !important;
    }

    .margin-bottom-xxlarge {
        margin-bottom: 10em !important;
    }
}

.no-padding-top, .no-top-padding {
  padding-top: 0 !important;
}
.no-padding-bottom, .no-bottom-padding {
  padding-bottom: 0 !important;
}


.padding-top-small {
    padding-top: .5em !important;
}
.padding-bottom-small {
    padding-bottom: .5em !important;
}
.padding-top-medium {
    padding-top: 1.5em !important;
}
.padding-bottom-medium {
    padding-bottom: 1.5em !important;
}
.padding-top-large {
    padding-top: 2.5em !important;
}
.padding-bottom-large {
    padding-bottom: 2.5em !important;
}
.padding-top-xlarge {
    padding-top: 3.5em !important;
}
.padding-bottom-xlarge {
    padding-bottom: 3.5em !important;
}
.padding-top-xxlarge {
    padding-top: 5em !important;
}
.padding-bottom-xxlarge {
    padding-bottom: 5em !important;
}
@include tablet {
    .padding-top-small {
      padding-top: 1em !important;
    }
    .padding-bottom-small {
      padding-bottom: 1em !important;
    }
    .padding-top-medium {
      padding-top: 3em !important;
    }
    .padding-bottom-medium {
      padding-bottom: 3em !important;
    }
    .padding-top-large {
      padding-top: 5em !important;
    }
    .padding-bottom-large {
      padding-bottom: 5em !important;
    }
    .padding-top-xlarge {
      padding-top: 7em !important;
    }
    .padding-bottom-xlarge {
      padding-bottom: 7em !important;
    }
    .padding-top-xxlarge {
      padding-top: 10em !important;
    }
    .padding-bottom-xxlarge {
      padding-bottom: 10em !important;
    }
}


.linkbox {
    cursor: pointer;
}

.CodeMirror-code {
    font-size: 12px;
    text-transform: none;
}


/** Lazy loaded backgrounds **/
.lazy { background-image: none; }

/** Icon Colors **/
.svg-blue {
    filter: invert(71%) sepia(48%) saturate(496%) hue-rotate(177deg) brightness(104%) contrast(101%);
}

.svg-gold {
    filter: invert(72%) sepia(71%) saturate(288%) hue-rotate(2deg) brightness(94%) contrast(88%);
}

.svg-green {
    //filter: invert(30%) sepia(13%) saturate(4501%) hue-rotate(114deg) brightness(101%) contrast(104%);
    //filter: invert(30%) sepia(15%) saturate(1919%) hue-rotate(68deg) brightness(101%) contrast(174%);
    filter: invert(28%) sepia(16%) saturate(2484%) hue-rotate(104deg) brightness(98%) contrast(98%);
}
.svg-white {
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(109deg) brightness(98%) contrast(107%);
}


html:not(.hydrated) {
    button * {
        pointer-events: none;
    }

    /* TRY WITHOUT THIS BUT MIGHT CAUSE ISSUES WITH JAVASCRIPT WHEN CLICKING ON SPEEDBUMP LINK WITH IMAGE IN IT
    @include tablet {
        a * {
            pointer-events: none;
        }
    }
    */
    a.speedbump * {
        pointer-events: none;
    }
}

/** Backgrounds ***/
.bg-white {
    background: $white !important;
    color: $black;

    > .content {
        color: $black;

        h2, h3 {
            color: $primary-color;
        }
    }

    .cta-lnk-white {
        color: $primary-color;
    }
}
.bg-blue {
    background: $primary-color !important;
    color: $white;

    > .content {
        color: $white;

        h2, h3, h4, h5 {
            color: $white;
        }
    }

    .cta-lnk {
        color: $white;
    }
}
.bg-green {
    background: $secondary-color !important;
    color: $white;

    > .content {
        color: $white;

        h2, h3, h4, h5 {
            color: $white;
        }
    }

    .cta-lnk {
        color: $white;
    }
}
.bg-gray {
    background: $yellow-background-color !important;
    > .content {
        color: $black;
        h2,h3 {
            color: $primary-color;
        }

        .cta-lnk-white {
            color: $primary-color;
        }
    }
}

.bg-light-blue {
    background: $yellow-background-color !important;

    .cta-lnk-white {
        color: $primary-color;
    }
}
