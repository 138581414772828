/* Buttons */

/* Focus Styles */
a:focus,button:focus,input:focus,select:focus {
    outline: 1px dotted #3a87ad;
}

div:focus, section:focus {
  outline: none;
  box-shadow: none;
}

.base-btn {
    display: inline-block;
    font-size: 1.6rem;
    padding: 0.7em 3em;
    font-weight: 700;
    border-width: 2px;
    border-style: solid;
    text-align: center;
    position: relative;
    line-height: 1.3;
    transition: all 0.5s ease;
    border-radius: 30px;
}

.cta-btn {
    @extend .base-btn;
    background: $secondary-color;
    color: $black;
    border-color: $secondary-color;
    &:hover {
        border-color: $black;
    }
}

.ghost-btn {
    @extend .base-btn;
    background: transparent;
    color: $body-link-color;
    border-color: $secondary-color;
    transition: background 0.5s ease, border-color 0.5s ease;
    &:focus, &:hover {
        background-color: $secondary-color;
        border-color: $body-link-color;
        transition: background 0.5s ease, border-color 0.5s ease;
    }
}

.ghost-btn-white {
    @extend .base-btn;
    background: transparent;
    color: $white;
    border-color: $secondary-color;
    transition: color 0.5s ease, background 0.5s ease, border-color 0.5s ease;
    &:focus, &:hover {
        color: $body-link-color;
        background-color: $white;
        border-color: $body-link-color;
        transition: color 0.5s ease, background 0.5s ease, border-color 0.5s ease;
    }
}

.video-btn {
    @extend .base-btn;
    padding: 0.7em 3em 0.7em calc(3em + 20px);
    background: url('../Images/body_icon_play-button.svg') $secondary-color no-repeat 3em center / 13px 15px;
    color: $black;
    border-color: $secondary-color;
    transition: border-color 0.5s ease;
    &:focus, &:hover {
        border-color: $black;
        transition: border-color 0.5s ease;
    }
}
.android-btn {
    color: transparent;
    border-color: #000;
    background: url(../Images/button_android_google_play.svg) #000 no-repeat center/158px 38px;
    min-width: 200px;
    min-height: calc(38px + 1em - 4px);
    border-radius: 30px;
}
.apple-btn {
    color: transparent;
    border-color: #000;
    background: url(../Images/button_apple_app_store.svg) #000 no-repeat center/142px 38px;
    min-width: 200px;
    min-height: calc(38px + 1em - 4px);
    border-radius: 30px;
}
