.alerts {
    margin: 0;
    padding: 0;
    list-style: none;
    &:empty {
        display: none;
    }
}

.alert {
    margin: 0;
    padding: 1em 55px 1em 1em;
    text-align: right;
    position: relative;
    min-height: 50px;
    @include tablet {
        padding: 1em;
    }
    .alert-close {
        width: 50px;
        height: 50px;
        transform: scale(1);
        transition: transform 0.5s ease;
        position: absolute;
        top: 0;
        right: 0;
        &:focus, &:hover {
            transform: scale(1.1);
            transition: transform 0.5s ease;
        }
        @include tablet {
            top: 50%;
            transform: translateY(-50%) scale(1);
            transition: transform 0.5s ease;
            &:focus, &:hover {
                transform: translateY(-50%) scale(1.1);
                transition: transform 0.5s ease;
            }
        }
    }
    .body {
        margin: 0;
        font-size: 1.4rem;
        text-align: center;
        width: calc(100% - 50px);
        > *:last-child {
            margin: 0;
        }
    }
    a {
        font-size: inherit;
        font-weight: bold;
        text-decoration: underline;
    }
    &.style-notice {
        background-color: $notice-color;
        color: $body-font-color;
        .alert-close {
            background: url('../Images/header_alert_close-notice.svg') transparent no-repeat center / 29px 29px;
        }
        a {
            color: $body-font-color;
        }
    }
    &.style-alert {
        background-color: $alert-color;
        color: $white;
        .alert-close {
            background: url('../Images/header_alert_close-alert.svg') transparent no-repeat center / 29px 29px;
        }
        a {
            color: $white;
        }
    }
}
