.block-about-community {
    margin-left: auto;
    margin-left: auto;
    margin-bottom: 3.5em;
    > .pad {
        margin: 0 auto;
        padding-left: $mobile-padding;
        padding-right: $mobile-padding;
        > .content {
            margin: 0 auto;
            @include max-wrap;
            display: grid;
            gap: 5em 0;
            @include responsive(700px) {
                grid-template-columns: 65% 30%;
                align-items: center;
                gap: 0 5%;
            }
        }
    }
    .image {
        margin: 0;
        text-align: center;
        svg {
            width: 100%;
            height: auto;
        }
    }
    .text {
        margin: 0 auto;
        display: grid;
        gap: 2rem 0;
        text-align: center;
        max-width: 350px;
        @include responsive(700px) {
            text-align: left;
            max-width: 100%;
        }
        .title {
            margin: 0 auto;
        }
        .body {
            font-size: 1.6rem;
            @include tablet {
                @include fontSize(2);
            }
            > *:last-child {
                margin: 0;
            }
        }
        .actions {
            margin: 0;
            padding: 0;
            list-style: none;
            display: grid;
            gap: 2rem 0;
        }
    }
}
