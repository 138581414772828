.block-benefit-checklist {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3.5em;

    > .pad {
        margin: 0 auto;
        padding-left: $mobile-padding;
        padding-right: $mobile-padding;
        padding-top: 2.5em;
        padding-bottom: 2.5em;

        @include tablet {
            padding-top: 2.5em;
        }

        @media screen and (min-width: 1050px) {
            padding-top: 5.5em;
        }

        @media screen and (min-width: 1200px) {
            padding-top: 6.5em;
        }

        @media screen and (min-width: 1300px) {
            padding-top: 7.5em;
        }

        > .content {
            margin: 0 auto;
            display: block;
            @include max-wrap;
            text-align: center;

            @include tablet {
                display: flex;
                justify-content: center;
                gap: 0 5rem;
                align-items: center;
            }
        }
    }

    .headline {
        margin: 0;
        text-align: center;

        @include tablet {
            flex-basis: 50%;
            width: 50%;
            text-align: right;
        }

        .title {
            margin: 0 auto 1em;
            font-family: $heading-font;
            color: $body-link-color;
            font-weight: bold;
            font-size: 2.2rem;
            max-width: 400px;

            @include tablet {
                margin: 0 0 0 auto;
                @include fontSize(4);
                max-width: 100%;
            }
        }
    }

    .list {
        margin: 0 auto;

        @include tablet {
            display: grid;
            flex-basis: 50%;
            width: 50%;
            justify-content: flex-start;
        }

        ul {
            margin: 0 auto;
            padding: 0;
            list-style: none;
            display: inline-grid;
            gap: 0.5em 0;
            text-align: left;
        }

        li {
            margin: 0;
            padding: 0 0 0 1.5em;
            background: url('../Images/body_checkmark.svg') transparent no-repeat left 0.35em / 1em auto;
            font-size: 1.8rem;

            @include tablet {
                flex-basis: 50%;
                @include fontSize(2);
            }
        }
    }
}
