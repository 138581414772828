.block-promotions-services {
    margin-left: auto;
    margin-left: auto;
    margin-bottom: 3.5em;
    text-align: center;
    > .pad {
        margin: 0 auto;
        padding-left: $mobile-padding;
        padding-right: $mobile-padding;
        > .content {
            margin: 0 auto;
            @include max-wrap;
        }
    }
    .title {
        margin: 0 auto 1.5em;
        font-family: $heading-font;
        font-weight: bold;
        font-size: 2.2rem;
        line-height: 1;
        @include tablet {
            @include fontSize(4);
        }
    }
    .items {
        margin: 0;
        padding: 0;
        list-style: none;
        display: grid;
        gap: 5em 0;
        @include responsive(600px) {
            display: flex;
            flex-wrap: wrap;
            gap: 5em 5em;
            align-items: flex-start;
            justify-content: center;
        }
    }
    .item {
        margin: 0;
        padding: 0;
        list-style: none;
        display: grid;
        gap: 1.5rem 0;
        @include responsive(600px) {
            width: 100%;
            max-width: 335px;
        }
        &:nth-child(odd) {
            svg.yellow-stroke {
                display: block;
            }
            svg.blue-stroke {
                display: none;
            }
        }
        &:nth-child(even) {
            svg.yellow-stroke {
                display: none;
            }
            svg.blue-stroke {
                display: block;
            }
        }
        svg {
            width: 100%;
            height: auto;
        }
        .item-title {
            margin: 0 auto;
            font-weight: bold;
            font-size: 1.8rem;
            @include tablet {
                @include fontSize(2.2);
            }
        }
        .item-body {
            font-size: 1.6rem;
            @include tablet {
                @include fontSize(1.8);
            }
            > *:last-child {
                margin: 0;
            }
        }
    }
}
