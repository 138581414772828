// Images and Videos
//

img,
video,
audio,
iframe,
object {
  max-width: 100%;
}
img:not([src$=".svg"]) {
  height: auto;
}

.vid-transcript-content {
}

.video-pop-modal {
  background: rgba(0,0,0,.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 99999999;
  padding: 30px;
  * {
    box-sizing: border-box;
  }
  .video-pop-modal-content {
    @include clearfix;
    @include max-wrap;
    max-width: 1000px;
    .video-pop-modal-frame {
      @include tablet {
        float: right;
        width: 60%;
      }
      text-align: right;
    }
    .js-close-video-pop {
      font-size: 72px;
      font-weight: 200;
      line-height: .7;
      color: #FFF;
      display: inline-block;
      margin-bottom: 15px; 
      border: none;
      background: none;
    }
    .video-pop-video {
      video { width: 100%; }
      .video-pop-frame {
        width: 100%;
        padding-bottom: 62.5%;
        position: relative;
      }
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    .video-pop-info {
      @include tablet {
        float: left;
        width: 40%;
        padding-right: 40px;
      }
      padding-top: 60px;
      color: #FFF;
    }
    .f-video-pop-title {
      font-size: 27px;
      @include tablet {
        font-size: 37px;
      }
      color: #FFF;
      margin-bottom: .5em; 
    }
    .video-pop-description {
      font-size: 12px;
      @include tablet {
        font-size: 18px;
      }
      color: #FFF;
      margin-bottom: 1em;
    }
    .video-pop-transcript {
      &.open {
        background: none;
      }
      .show-transcript-lnk {
        color: #FFF;
        font-size: 18px;
        text-decoration: underline;
        border: none;
        background: none;
      }
    }
  }
}
.video-pop-image {
  margin-bottom: .5em;
}


.video-listing {
    .videos {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;

        .video-container {
            margin: 0 15px 30px;
        }
    }
}
.fr-rounded {
    border-radius: 10px;
}